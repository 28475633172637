if (!CONFIG.intercomId) {
  window.Intercom = function () {};
}

var w = window;
var ic = w.Intercom;
var d = document;
var i = function () {
  i.c(arguments);
};
i.q = [];
i.c = function (args) {
  i.q.push(args);
};
w.Intercom = i;
function l() {
  var s = d.createElement('script');
  s.type = 'text/javascript';
  s.defer = true;
  s.src = 'https://widget.intercom.io/widget';
  var x = d.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);
}
if (w.attachEvent) {
  w.attachEvent('onload', l);
} else {
  w.addEventListener('load', l, false);
}
