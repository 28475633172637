export default {
  adminUrl: <string>CONFIG.adminUrl,
  animation: <boolean>CONFIG.animation,
  apiUrl: <string>CONFIG.apiUrl,
  appUrl: <string>CONFIG.appUrl,
  cannyId: <string>CONFIG.cannyId,
  environment: <string>CONFIG.environment,
  envIsZelis: <string>CONFIG.envIsZelis,
  gpiOnboardingUrl: <string>CONFIG.gpiOnboardingUrl,
  gpiPayFieldsApiKey: <string>CONFIG.gpiPayFieldsApiKey,
  intercomId: <string>CONFIG.intercomId,
  production: <boolean>CONFIG.production,
  routerApiUrl: <string>CONFIG.routerApiUrl,
  apiCellUrlTemplate: <string>CONFIG.apiCellUrlTemplate,
  sentryDsn: <string | null>CONFIG.sentryDsn,
  splitApiKey: <string | null>CONFIG.splitApiKey,
};
